// SearchBox.jsx
import React, { useState, useEffect, useRef } from 'react';
import data from '../company_tickers.json';
import './SearchBox.css';

const SearchBox = ({ onSearch, loading }) => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedTicker, setSelectedTicker] = useState(null); // Track if a ticker was selected
    const [focusedIndex, setFocusedIndex] = useState(-1); // Track the index of the focused suggestion
    const inputRef = useRef(null); // Ref for input element
    const debounceTimeout = useRef(null); // Ref for debounce timeout

    // Debounced function for updating suggestions
    const updateSuggestions = (query) => {
        const trimmedQuery = query.trim().toLowerCase(); // Trim and normalize the query
    
        const filteredSuggestions = Object.values(data).filter(item => {
            const ticker = item.ticker.toLowerCase().trim();
            const title = item.title.toLowerCase().trim();
            return ticker.startsWith(trimmedQuery) || title.startsWith(trimmedQuery);
        });
    
        setSuggestions(filteredSuggestions.slice(0, 10)); // Show only top 10 suggestions
    };    
    

    // Handle input change with debounce
    const handleInputChange = (event) => {
        const value = event.target.value;
        setQuery(value);
        setSelectedTicker(null); // Reset selected ticker if input changes
        setFocusedIndex(-1); // Reset focused index when typing

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (value) {
                updateSuggestions(value);
            } else {
                setSuggestions([]);
            }
        }, 300); // Debounce delay of 300ms
    };

    // Handle selecting a suggestion
    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion.ticker); // Set the input value to the selected symbol
        setSelectedTicker(suggestion.ticker); // Mark the ticker as selected
        setSuggestions([]); // Immediately clear suggestions to hide the dropdown
    };

    // Trigger the research function when the user clicks the "Analyze" button
    const handleResearchClick = () => {
        if (selectedTicker) {
            onSearch(selectedTicker); // Send selected ticker symbol to the parent component
        } else {
            alert('Please select a valid ticker from the dropdown');
        }
    };

    // Handle keyboard navigation in the suggestion list
    const handleKeyDown = (event) => {
        if (suggestions.length > 0) {
            if (event.key === 'ArrowDown') {
                // Move focus down
                setFocusedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
            } else if (event.key === 'ArrowUp') {
                // Move focus up
                setFocusedIndex((prevIndex) =>
                    prevIndex === 0 ? suggestions.length - 1 : prevIndex - 1
                );
            } else if (event.key === 'Enter') {
                event.preventDefault(); // Prevent default form submission
                if (focusedIndex >= 0) {
                    // Select the focused suggestion
                    handleSuggestionClick(suggestions[focusedIndex]);
                } else {
                    // No suggestion is focused; trigger Analyze button
                    handleResearchClick();
                }
            }
        } else if (event.key === 'Enter') {
            // No suggestions are showing; trigger Analyze button
            event.preventDefault(); // Prevent default form submission
            handleResearchClick();
        }
    };

    // Handle click outside to close dropdown
    const handleClickOutside = (e) => {
        if (!e.target.closest('.search-box')) {
            setSuggestions([]);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (focusedIndex >= 0 && focusedIndex < suggestions.length) {
            const focusedSuggestion = suggestions[focusedIndex];
            setQuery(focusedSuggestion.ticker); // Update query to match focused suggestion
        }
    }, [focusedIndex]);

    return (
        <div className="search-box">
            <input
                ref={inputRef}
                type="text"
                placeholder="Type name or symbol"
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown} // Listen for arrow and enter key presses
                onFocus={() => query && updateSuggestions(query)} // Recalculate suggestions on focus
            />
            <button className="search-button" onClick={handleResearchClick} disabled={loading || !selectedTicker}>
                {loading ? 'Loading...' : 'Analyze'}
            </button>

            {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onMouseDown={() => handleSuggestionClick(suggestion)} // Use onMouseDown to capture before blur
                            className={`suggestion-item ${index === focusedIndex ? 'focused' : ''}`} // Add focused class
                        >
                            {suggestion.ticker} - {suggestion.title}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchBox; /* Container for the search box */
