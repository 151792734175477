import React, { useState, useEffect , useRef } from 'react';
import './Dashboard.css';
import { FaBars, FaTimes } from 'react-icons/fa'; // Importing icons
import { auth } from '../firebase-config';
import { useNavigate, useLocation, Outlet } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import SearchBox from './SearchBox';
import HistoryPage from './HistoryPage';
import UpgradePopup from './UpgradePopup';
import companyTickers from '../company_tickers.json'; 
import PredictionOutput from './PredictionOutput';
import Footer from './Footer';
import { handleGoogleSignIn } from './LoginPage';
import TrendingPage from './TrendingPage';
import { FiTrendingUp, FiClock, FiPieChart, FiStar } from 'react-icons/fi';
import AccountPage from './AccountPage';
import axios from 'axios';
import ScreenersPage from './ScreenersPage';
import { logUserActivity } from "./logActivity";

const pageTypeMapping = {
  "/": "HME",
  "/home": "HME",
  "/dashboard/history": "HIS",
  "/analyze": "AZE",
  "/dashboard": "DASH",
  "/dashboard/trending": "TRE",
  "/progress": "PRO",
  "/dashboard/screeners": "SCR",
};

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState('Loading...'); 
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [Flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [parsedPrediction, setParsedPrediction] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [hasFetched, setHasFetched] = useState(false); 
  const [fetchError, setFetchError] = useState(false); // For tracking errors
  const [isMenuOpen, setMenuOpen] = useState(false); // State for mobile menu
  const navigate = useNavigate();
  const location = useLocation();
  const { prediction } = location.state || {};
  const [anonUserId, setAnonUserId] = useState(localStorage.getItem('anonUserId') || null);
  const [dayBeforeFormatted, setDayBeforeFormatted] = useState(null);
  const [weekBeforeFormatted, setWeekBeforeFormatted] = useState(null);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false); // Popup visibility state
  const closeButtonRef = useRef(null);
  const disclaimerButtonRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [authLoading, setAuthLoading] = useState(false); // For authentication
  const [dataLoading, setDataLoading] = useState(false); // For data fetching
  const [progressLoading, setProgressLoading] = useState(false); // For progress bar simulation
  const params = new URLSearchParams(location.search);
  const pid = params.get('pid'); // Get the prediction ID from URL
  const urlActiveTab = params.get('activeTab'); // Get the prediction ID from URL
  // console.log('urlActiveTab', urlActiveTab);
  const [activeTab, setActiveTab] = useState(urlActiveTab || 'analyze'); 
  // console.log('activeTab', activeTab);
  const eventSourceRef = useRef(null);


  const createAnonymousUser = async () => {
    try {
      const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';
  
      // Fetch client IP address from an external service
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const clientIp = ipResponse.data.ip;
  
      // Make a request to create an anonymous user with client IP in the headers
      const response = await axios.post(`${baseURL}/create-anon-user`, {}, {
        headers: {
          'Client-IP': clientIp, // Add IP address to headers
        }
      });
  
      const { user_id } = response.data;
      localStorage.setItem('anonUserId', user_id);
      setAnonUserId(user_id);
      console.log('anonUserId', user_id);
    } catch (error) {
      console.error('Error creating anonymous user:', error);
    }
  };

const fetchCredits = async () => {
  const userId = localStorage.getItem('uid');
  const firebaseuid = localStorage.getItem('firebaseuid');
  if (!userId) {
    console.error("No user ID found in localStorage.");
    return;
  }
  if (!firebaseuid) {
    console.error("No firebase user ID found in localStorage.");
    return;
  }

  const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';
  
  try {
    const response = await fetch(`${baseURL}/get-credits`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userId}:${firebaseuid}`
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch credits. Status: ${response.status}`);
    }

    const result = await response.json();
    setCredits(result.credits_available);
    localStorage.setItem('credits', result.credits_available);  // Update localStorage
    console.log("Credits:", credits);
  } catch (error) {
    console.error("Error fetching credits:", error);
  }
};



useEffect(() => {
  const userId = localStorage.getItem("uid");
  const pageType = pageTypeMapping[location.pathname] || activeTab || "UNK"; 
  logUserActivity(userId, pageType);
  console.log(`Sent request to log-activity for page type: ${pageType}`);
}, [location.pathname]);

const signIn = async () => {
  setAuthLoading(true); // Set authentication loading state
  await handleGoogleSignIn(navigate, setLoading); // Handle Google sign-in

  try {
    const userId = localStorage.getItem('uid');
    const predictionId = localStorage.getItem('predictionId');

    if (!userId) {
      console.error('No user ID found in localStorage. Please sign in.');
      setAuthLoading(false);
      return;
    }

    const currentCredits = localStorage.getItem('credits');
    console.log("Credits after fetch:", currentCredits);

    // Handle insufficient credits
    if (Number(currentCredits) < Number(process.env.REACT_APP_CREDITS_TO_SUBTRACT_ANALYZE)) {
      localStorage.removeItem('predictionData');
      setFlag(true);
      setPopupOpen(true); // Show upgrade popup
      setStatusMessage('Insufficient credits. Please upgrade.');
      console.log("Insufficient credits. Please upgrade.");
      setAuthLoading(false);
      return;
    }

    // Continue if credits are sufficient
    const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV'
      ? 'http://127.0.0.1:5000'
      : 'https://flask-backend-52245432644.us-central1.run.app';

    const updatePredictionResponse = await fetch(`${baseURL}/update-prediction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prediction_id: predictionId,
        user_id: userId,
      }),
    });

    if (updatePredictionResponse.ok) {
      const storedPredictionData = localStorage.getItem('predictionData');
      if (storedPredictionData) {
        const parsedPredictionData = JSON.parse(storedPredictionData);
        setApiData(parsedPredictionData);
        setParsedPrediction(JSON.parse(parsedPredictionData.prediction));
        setStatusMessage('Prediction data loaded successfully!');
        
        await fetchCredits(); // Refresh credits after prediction update
      } else {
        console.error('No prediction data found in localStorage.');
      }
    } else {
      throw new Error('Failed to update prediction.');
    }
  } catch (error) {
    console.error('Error during sign-in process:', error);
    setStatusMessage(`Error during sign-in: ${error.message}`);
  } finally {
    setAuthLoading(false);
  }
};



    
  
  const getCompanyTitle = (ticker) => {
    const company = Object.values(companyTickers).find(
      (item) => item.ticker === ticker.toUpperCase()
    );
    return company ? company.title : 'Company Not Found';
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'reload') {
     fetchCredits();
    }
    if (location.state?.showUpgradePopup) {
      setPopupOpen(true);
    }
    if (location.state?.refreshCredits) {
      fetchCredits();
    }
  }, [location.state]);

  useEffect(() => {
    if (!(localStorage.getItem('uid')) && !(localStorage.getItem('anonUserId')) && !pid) {
      navigate('/'); // Redirect to login or another page
    } else {
      const storedCredits = localStorage.getItem('credits');
      if (storedCredits !== null) {
        setCredits(storedCredits);
        // fetchCredits();
      } else {
        setCredits(0);  // Set a default value like 0 if credits are missing or invalid
      }
    }
    

    if (prediction && !hasFetched) {
      setApiData(prediction);
      try {
        const parsedData = JSON.parse(prediction.prediction);
        setParsedPrediction(parsedData);
        localStorage.setItem('predictionData', JSON.stringify(prediction));
       // Save to localStorage
        setHasFetched(true); // Prevent re-fetching
      } catch (error) {
        console.error('Error parsing prediction:', error);
      }
    }

   
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        //fetchCredits(); // Uncomment if you have a fetchCredits function
      } else {
        setUser(null); 
      }
    });

    return () => unsubscribe();
  }, [apiData, prediction, hasFetched, user, navigate, credits]);


  const handleFetchData = async (ticker) => {
    if (!ticker) {
      alert('Please enter a ticker symbol');
      return;
    }
    if (Number(credits) < Number(process.env.REACT_APP_CREDITS_TO_SUBTRACT_ANALYZE)) {
      setPopupOpen(true);
      return;
    }
    localStorage.removeItem('predictionData'); // Clear old prediction data
    
  
    // Clear states for new fetch
  setApiData(null);
  setParsedPrediction(null);
  setStatusMessage('');
  setHasFetched(false);
  setFetchError(false);
  setProgressLoading(true); // Ensure progress bar is shown
  setProgress(0);
  console.log("Progress loading started:", progressLoading);
  setDataLoading(true);

  
    try {
      const userId = localStorage.getItem('uid') || anonUserId;
      const firebaseuid = localStorage.getItem('firebaseuid') || null;
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV'
        ? 'http://127.0.0.1:5000'
        : 'https://flask-backend-52245432644.us-central1.run.app';
  
      const authToken = firebaseuid ? `${userId}:${firebaseuid}` : userId;
  
      // Start listening to progress updates from the backend
      const eventSource = new EventSource(`${baseURL}/get-prediction?ticker=${ticker}&auth=${authToken}`);
  
      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received event data:", data);
  
        if (data.error) {
          console.error('Error:', data.error);
          setFetchError(true);
          setStatusMessage(`Error: ${data.error}`);
          eventSource.close();
          setProgressLoading(false); // Hide progress bar on error
          return;
        }
  
        // Update progress and status message
        if (data.progress !== undefined) {
          setProgress(data.progress);
          setProgressLoading(true);
          console.log("Progress updated:", data.progress);
        }
    

        if (data.status) {
          setStatusMessage(data.status);
          console.log("Status updated:", data.status);
        }

        if (data.progress === 100) {
          setProgressLoading(false); // Hide progress bar when complete
        }
  
        // Final data from the backend
        if (data.data) {
          setApiData(data.data);
  
          if (data.data.prediction) {
            try {
              const parsedData = JSON.parse(data.data.prediction);
              setParsedPrediction(parsedData);
              setHasFetched(true);
            } catch (error) {
              console.error('Error parsing prediction:', error);
            }
          }
  
          setProgress(100);
          setStatusMessage('Prediction completed!');
          setProgressLoading(false); // Hide progress bar on completion
          fetchCredits(); // Fetch credits after prediction completes
          eventSource.close();
        }
      };
  
      eventSource.onerror = (error) => {
        console.error('EventSource error:', error);
        setFetchError(true);
        setStatusMessage('Error fetching data. Please try again.');
        setProgressLoading(false);
        eventSource.close();
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchError(true);
      setStatusMessage(`Error: ${error.message}`);
    } finally {
      setDataLoading(false);
      setProgressLoading(false);
    }
  };

  // useEffect(() => {
  //   const eventSourceRef = { current: null };
  
  //   return () => {
  //     if (eventSourceRef.current) {
  //       eventSourceRef.current.close();
  //     }
  //   };
  // }, []);
  
  

  useEffect(() => {
    if (pid && !user && !anonUserId ) { // If there's a pid and the user is not logged in
     createAnonymousUser(); // Fetch the prediction data
    }
     else if (pid) {
      handleDisplayData(pid);
     }
    }
  , [location, user, anonUserId]); // Re-run if location or user status changes

  const handleDisplayData = async (prediction_id) => {

      const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' 
        ? 'http://127.0.0.1:5000' 
        : 'https://flask-backend-52245432644.us-central1.run.app';

        try {
          let userID = user ? localStorage.getItem('uid'): localStorage.getItem('anonUserId');
          let firebase_uid = user ? user.uid : ' ';
          const response = await fetch(`${baseURL}/get-prediction-json`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ prediction_id: prediction_id, user_id: userID, firebase_uid: firebase_uid }),
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
          }
          const result = await response.json();
          console.log(prediction_id);
          console.log(result);
          if (user) {
            // If user is logged in, display full data
            setApiData(result.prediction_json[1]);
            setParsedPrediction(result.prediction_json[1]);
            await fetchCredits();
          } else {
            // If user is anonymous, display masked data
            const maskedData = {
              ...result.prediction_json[1],
              prediction: 'Predicted data is hidden for anonymous users. Please log in to view details.',
            };
            setApiData(maskedData);
            setParsedPrediction(maskedData);
          }
          setStatusMessage('Prediction data loaded successfully!');
          // Handle the response here (e.g., check response.ok and parse the JSON)
        } catch (error) {
          setFetchError(true);
          setStatusMessage(`Error fetching prediction data: ${error.message}`);
        }
        finally {
      setDataLoading(false);
      localStorage.removeItem('anonUserId');
    }
  };
  

   // Function to open the disclaimer popup
   const openDisclaimer = () => {
    setIsDisclaimerOpen(true);
    document.body.style.overflow = 'hidden'; // Prevent background scrolling
  };

  // Function to close the disclaimer popup
  const closeDisclaimer = () => {
    setIsDisclaimerOpen(false);
    document.body.style.overflow = 'auto'; // Re-enable background scrolling
    // Return focus to the disclaimer button
    if (disclaimerButtonRef.current) {
      disclaimerButtonRef.current.focus();
    }
  };

  // Handle Escape key to close the popup
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isDisclaimerOpen) {
        closeDisclaimer();
      }
    };

    document.addEventListener('keydown', handleEscape);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isDisclaimerOpen]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const dropdownRef = useRef(null);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

  

  const handleLogout = async () => {
    try {
    // Clear all stored data in localStorage on logout
    localStorage.removeItem('uid');  // Remove user ID from localStorage
    localStorage.removeItem('predictionData'); // Remove prediction data from localStorage
    localStorage.clear() 
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const handleAccount = async () => {
    setActiveTab('account');
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const currentTab = location.pathname.split('/')[2] || 'analyze';
    if (urlActiveTab === null) {
      setActiveTab(currentTab);
    }
  }, [location]);



  return (
    <div className="logged-in-page">
      <header className="header-dashboard">
        <div className="header-left">
          {/* Hamburger Menu Icon for Mobile */}
          <button 
            className="hamburger-menu" 
            onClick={toggleMenu} 
            aria-label="Toggle navigation menu"
          >{isMenuOpen ? <FaTimes /> : <FaBars />}
            <span></span>
            <span></span>
            <span></span>
          </button>

          <h3 style={{ display: 'inline-block', position: 'relative' }}> 
          <a href="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          MarketCrunch AI
      <span style={{ fontSize: '0.75em', position: 'relative', top: '-0.2em' }}>&#8482;</span>
      <span
        style={{
          display: 'block',
          fontSize: '0.5em',           
          position: 'absolute',
          left:'75%',                   
          top: '2.3em',                 
          fontStyle: 'italic',
          letterSpacing: '0.05em',
        }}
      >
        BETA
      </span>
      </a>
          </h3>
        </div>
    
 
        {/* <div className="header-right">
          {user ? (
            <>
              <button className="logout-button" onClick={handleLogout}>Logout</button>
              <div className="user-icon">{user?.displayName?.charAt(0)}</div>
            </>
          ) : (
            <button className="signin-button" onClick={() => navigate('/login')}>Free Sign-up</button>
          )}
        </div> */}

<div className="header-right" ref={dropdownRef}>
  {user ? (
    <div className="user-dropdown logout-dropdown">
      <div className="user-icon" onClick={toggleDropdown} aria-haspopup="true" aria-expanded={isDropdownOpen}>
        {user?.displayName?.charAt(0)}
      </div>
      {isDropdownOpen && (
        <div className="dropdown-menu">
          <button className="logout-button" onClick={handleLogout}>Logout</button>
          <button className="logout-button" onClick={handleAccount}>Account</button>
        </div>
      )}
    </div>
  ) : (
    <button className="signin-button" onClick={signIn}>Free Sign-up</button>
  )}
</div>

      </header>

      {/* Sidebar Navigation - Visible on Desktop, Hidden on Mobile */}
      
      <div className={`sidebar-nav ${isMenuOpen ? 'active' : ''}`}>
  <button 
    onClick={() => { setActiveTab('analyze'); setMenuOpen(false); navigate('/dashboard'); }} 
    className={activeTab === 'analyze' ? 'active-tab' : ''}
  >
    <FiPieChart style={{ marginRight: '8px' }} /> Analyze
  </button>
  
  {user && (
    <div className='hidden-tabs-anon'>
      <button 
        onClick={() => { setActiveTab('trending'); setMenuOpen(false); navigate('/dashboard/trending'); }} 
        className={activeTab === 'trending' ? 'active-tab' : ''}
      >
        <FiTrendingUp style={{ marginRight: '8px' }} /> Trending
      </button>
       {/* <button 
        onClick={() => { setActiveTab('top-predictions'); setMenuOpen(false); }} 
        className={activeTab === 'top-predictions' ? 'active-tab' : ''}
      >
        <FiStar style={{ marginRight: '8px' }} /> Top Predictions
      </button> */}

      <button 
              onClick={() => { navigate('/dashboard/screeners'); setMenuOpen(false); }} 
              className={activeTab === 'screeners' ? 'active-tab' : ''}
            >
        <FiStar style={{ marginRight: '8px' }} /> Screeners
      </button>

      <button 
        onClick={() => { setActiveTab('history'); setMenuOpen(false); navigate('/dashboard/history'); }} 
        className={activeTab === 'history' ? 'active-tab' : ''}
      >
        <FiClock style={{ marginRight: '8px' }} /> History
      </button>
    </div>
  )}
</div>
      {/* Overlay when mobile menu is open */}
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}

      <div className="main-content">
        {user && (
              <div className='credits-container'>
                <div className="credits-display-container">
                <div className="wallet-icon">
                  <i className="fas fa-wallet"></i>
                </div>
                <div className="credits-info">
                  <span>{credits !== null ? credits : 'Loading...'} credits</span>
                  <p></p>
                </div>
              </div>
              </div>
            )} 

        {activeTab === 'analyze' && (
          <div className="dashboard-search-container">
            <SearchBox onSearch={handleFetchData} loading={loading} />
            {user && (
            <div className='dashboard-disclaimer'>
            <p> Costs {process.env.REACT_APP_CREDITS_TO_SUBTRACT_ANALYZE} model credits/analysis or view <Link to="/dashboard/trending" onClick={() => setActiveTab('trending')}>Trending</Link> analysis ({process.env.REACT_APP_CREDITS_TO_SUBTRACT_TRENDING} model credit) </p>
            </div>
            )}
          
            <div className="filters">
              <label>
                <input type="checkbox" /> Technicals
              </label>
              <label>
                <input type="checkbox" /> Fundamentals
              </label>
              <label className="tooltip">
                <input type="checkbox" disabled /> Macro
                <span className="tooltip-text">Coming soon!</span>
              </label>
              <br/>
            </div>
            {/* Welcome Message */}
            {!parsedPrediction && !dataLoading && !progressLoading && (
              <div className="welcome-message"> 
                <p>Our advanced AI analyzes complex data from fundamentals, technicals, and other sources to give you real, detailed, and impactful insights.</p> 
                <p>No hallucinations.</p>
              </div>
            )}
            
            
            
          </div>
        )}

        {activeTab === 'analyze' && (
          <div>
            {progressLoading &&  (
              <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                <p>{progress}% completed - {statusMessage}</p>
              </div>
            )}
            <div className="blur-wrapper">
              {!user && !authLoading &&(
                <div className="overlay-content">
                  <p className="sign-up-text">Access your Free AI Analysis and Prediction now!  <br/>No credit card needed.</p>
                  <button className="sign-in-button" onClick={signIn}>
                    <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google Icon" className="google-icon"/>
                    Continue with Google
                  </button>
                </div>
              )}
              {parsedPrediction && !dataLoading && !progressLoading && (
                <div className={`prediction-data ${!user ? 'blurred' : ''} && ${!Flag? '': 'blurred'}`}>
                  <br/>
                  
                  <div className="stock-container">
                    <div className="title-container" >
                      <h2>{getCompanyTitle(apiData.ticker)} ({apiData.ticker})</h2>
                      <p>AI-model predicts {parsedPrediction.price_target}: ({parsedPrediction.next_day_change_percent}) for the {parsedPrediction.pred_date} session.</p>
                    </div>
                    {/*   UNHIDE this comment when the API is working.
                      <div className="stock-grid"> 
                        <div className="side-by-side">
                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.day_change.toFixed(2)}%</span>
                            <p>{apiData?.dayBeforeFormatted}</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.week_change.toFixed(2)}%</span>
                            <p>{apiData?.weekBeforeFormatted}</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.month_change.toFixed(2)}%</span>
                            <p>Last 1 mo</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.quarter_change.toFixed(2)}%</span>
                            <p>Last 3 mo.</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.year_change.toFixed(2)}%</span>
                            <p>Last 1yr</p>
                          </div>

                          <div className="stock-grid-item">
                            <span className="percentage">{parsedPrediction.ytd_change.toFixed(2)}%</span>
                            <p>YTD</p>
                          </div>
                        </div>
                      </div>
                    */}
                  </div>
                  <div className='prediction-graph'>
                    <PredictionOutput 
                      gptOutput={parsedPrediction.gpt_output}  
                      featureImportance={parsedPrediction.feature_importance} 
                      positiveFeatures={parsedPrediction.positive_features}  // Pass positive features here
                      negativeFeatures={parsedPrediction.negative_features}  // Pass negative features here
                      ticker={apiData.ticker}
                    />
                    </div>

                    
                </div>
              )}
              
            </div>
          </div>
        )}
 
        {activeTab === 'history' && <HistoryPage updateActiveTab={setActiveTab} handleDisplayData={handleDisplayData} />}
          
        {activeTab === 'trending' && <TrendingPage updateActiveTab={setActiveTab} handleDisplayData={handleDisplayData} />}

        {activeTab === 'ScreenersPage' && <ScreenersPage updateActiveTab={setActiveTab} />}

        {activeTab === 'account' && <AccountPage setCredits={setCredits} />}
        <Outlet />
        {/* {activeTab === 'top-predictions' && <TopPredictionsPage />} */}
         
      </div>

      <UpgradePopup 
        isOpen={isPopupOpen} 
        handleClose={() => setPopupOpen(false)} 
      />
      
    <Footer />
   
    </div>
  );
};

export default Dashboard;