// Privacy.jsx
import React, { useEffect, useRef } from 'react';
import './Privacy.css';

const Privacy = ({ isOpen, onClose, privacyButtonRef }) => {
  const closeButtonRef = useRef(null);

  // Handle Escape key to close the popup
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  // Prevent background scrolling when the popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Focus the close button for accessibility
      if (closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // Return focus to the privacy button when closing
  const handleClose = () => {
    onClose();
    if (privacyButtonRef && privacyButtonRef.current) {
      privacyButtonRef.current.focus();
    }
  };

  if (!isOpen) return null;

  return (
    <div className='privacy'>
        <div className="popup-overlay" onClick={handleClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button
          className="close-button"
          onClick={handleClose}
          ref={closeButtonRef}
          aria-label="Close"
        >
          &times;
        </button>
        <h2>Privacy</h2>
        <h3>Personal Information We Collect</h3>
    <p>We collect information that, alone or in combination with other data, can be used to identify you (“Personal Information”).</p>

    <h4>1. Personal Information You Provide</h4>
    <h5>Account Information:</h5>
    <p>If you create an account, we collect your name, email address, and password.</p>

    <h5>Communication Information:</h5>
    <p>If you contact us, we collect your contact information and the content of your communication.</p>

    <h5>Payment Information:</h5>
    <p>When you purchase credits or subscribe to our services, we may collect payment details through third-party payment processors.</p>

    <h4>2. Social Media Interactions</h4>
    <p>When you engage with us through platforms such as LinkedIn, X (Twitter), or YouTube, we may collect information you choose to provide (e.g., contact details, comments). Aggregated analytics may also be shared with us by these platforms.</p>

    <h4>3. Technical Information</h4>
    <p>We automatically collect technical information such as IP addresses, browser types, usage data, and cookies to understand how you use our platform and improve performance.</p>

    <h4>4. Customer Support Information</h4>
    <p>If you contact us for support, we collect relevant information to resolve your issues, including contact details and problem descriptions.</p>

    <h4>5. Analytics and Cookies</h4>
    <p>We use cookies and similar technologies to track interactions with our services and enhance user experience. You can manage your cookie preferences through your browser settings.</p>

    <h3>How We Use Personal Information</h3>
    <p>We do not sell your personal data. We use it for the following purposes:</p>
    <ul>
        <li><strong>Service Delivery:</strong> To provide and maintain our platform and process your predictions.</li>
        <li><strong>Communication:</strong> To send transactional emails, updates, and marketing communications.</li>
        <li><strong>Security and Fraud Prevention:</strong> To monitor for fraudulent activity and ensure platform security.</li>
        <li><strong>Legal Compliance:</strong> To comply with legal obligations and respond to requests from public authorities.</li>
    </ul>

    <h3>Aggregated Information</h3>
    <p>We may aggregate data to analyze trends, improve our services, and generate insights. Aggregated data does not identify individuals and may be shared publicly or with third parties.</p>

    <h3>Disclosure of Personal Information</h3>
    <p>We may share personal data under the following circumstances:</p>
    <ul>
        <li><strong>Vendors and Service Providers:</strong> With third-party providers (e.g., payment processors) to support platform operations.</li>
        <li><strong>Business Transfers:</strong> In case of mergers, acquisitions, or sale of assets.</li>
        <li><strong>Legal Obligations:</strong> To comply with legal obligations or protect our legal rights.</li>
        <li><strong>Affiliates:</strong> With subsidiaries or affiliates who comply with this Privacy Policy.</li>
    </ul>

    <h3>Data Retention and Deletion</h3>
    <p>We retain personal information only as long as necessary for business purposes or to comply with legal requirements. Users can request data deletion by contacting us at <a href="mailto:info@marketcrunch.ai">info@marketcrunch.ai</a>. Upon verification, we will delete the data unless required to retain it by law.</p>

    <h3>Security</h3>
    <p>We implement industry-standard technical and organizational measures to protect personal information from unauthorized access, misuse, or disclosure. Measures include encryption, network monitoring, and physical access controls.</p>

    <h3>User Rights and Requests</h3>
    <p>Users can:</p>
    <ul>
        <li>Request access, correction, or deletion of their personal data.</li>
        <li>Opt out of marketing communications at any time.</li>
        <li>Contact us for data portability requests at <a href="mailto:info@marketcrunch.ai">info@marketcrunch.ai</a>.</li>
    </ul>
    <p>We will comply with requests in accordance with applicable law.</p>

    <h3>No Children Policy</h3>
    <p>Our platform is not directed at children under the age of 16. We do not knowingly collect personal data from minors. If you believe that a child has provided us with personal information, contact us at <a href="mailto:info@marketcrunch.ai">info@marketcrunch.ai</a>, and we will delete the information promptly.</p>

    <h3>Links to Other Websites</h3>
    <p>Our platform may contain links to third-party websites or social media platforms. These sites have their own privacy policies, and we are not responsible for their practices.</p>

    <h3>International Users</h3>
    <p>By using our platform, you acknowledge that your personal data may be transferred to servers located in the United States or other countries where our providers operate. We take appropriate safeguards to protect data during cross-border transfers.</p>

    <h3>Privacy Frameworks</h3>
    <p>We comply with applicable privacy frameworks for cross-border data transfers, including the European Union, UK, and Swiss adequacy decisions.</p>

    <h3>Online Tracking and Do Not Track Signals</h3>
    <p>Our platform does not respond to “Do Not Track” (DNT) signals. Tracking is conducted as outlined in this Privacy Policy, regardless of DNT preferences.</p>

    <h3>Changes to This Privacy Policy</h3>
    <p>We may update this Privacy Policy periodically. Updates will be posted on our website, and your continued use of the platform constitutes acceptance of the revised policy.</p>

    <h3>How We Use Personal Information</h3>
    <p>We may use your personal information to:</p>
    <ul>
        <li>Send you transactional emails regarding your account, including confirmation emails, usage updates, or critical notifications related to the Service.</li>
        <li>Provide marketing communications, including information about new features, updates, offers, and other relevant services. You may unsubscribe from promotional emails at any time by clicking the “unsubscribe” link in the email or by contacting us at <a href="mailto:info@marketcrunch.ai">info@marketcrunch.ai</a>.</li>
    </ul>

    <h3>User Rights and Requests</h3>
    <p>You can opt out of receiving marketing emails at any time by following the instructions in our emails or contacting us at <a href="mailto:info@marketcrunch.ai">info@marketcrunch.ai</a>. Opting out of promotional emails will not affect your ability to receive important service notifications.</p>

    <h3>Compliance with Laws</h3>
    <p>MarketCrunch AI complies with applicable laws governing electronic communications, including the CAN-SPAM Act, GDPR, and CASL.</p>
    <p>We ensure that all marketing emails include clear identification, sender information, and unsubscribe options as required by law.</p>
    <p>We will not send unsolicited emails or share your personal information with third parties for marketing purposes without your consent.</p>

    <h3>Contact Us</h3>
    <p>For any privacy-related inquiries, please contact:</p>
    <p>Email: <a href="mailto:info@marketcrunch.ai">info@marketcrunch.ai</a></p>
      </div>
    </div>
    </div>
  );
};

export default Privacy;
