// src/components/FAQPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './FAQPage.css';
import faqsData from './faqs.json';
import { auth } from '../firebase-config'; // Import auth from your firebase config
import { onAuthStateChanged } from 'firebase/auth'; // Import onAuthStateChanged

const FAQPage = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeFaq, setActiveFaq] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [user, setUser] = useState(null); // Store current user
  const navigate = useNavigate();

  // Function to handle redirect based on user authentication
  const handleRedirect = () => {
    user ? navigate('/dashboard') : navigate('/login');
  };

  // Check if user is logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    setFaqs(faqsData);
  }, []);

  const handleCategoryClick = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
    setActiveFaq(null); // Reset active FAQ when changing categories
  };

  const handleFaqClick = (index) => {
    setActiveFaq(activeFaq === index ? null : index);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setActiveCategory(null); // Reset active category on new search
    setActiveFaq(null); // Reset active FAQ on new search
  };

  const filterFaqs = () => {
    if (!searchTerm) return faqs;

    return faqs
      .map((category) => {
        const filteredFaqs = category.faqs.filter(
          (faq) =>
            faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
            faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return { ...category, faqs: filteredFaqs };
      })
      .filter((category) => category.faqs.length > 0);
  };

  const filteredFaqs = filterFaqs();

  return (
    <div className="faq-page">
      {/* Dashboard Header */}
      <div className="header">
        {/* Logo */}
        <h3>
          <a href="#top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            MarketCrunch AI&#8482;
          </a>
        </h3>

        {/* Header Right: Sign-in Button */}
        <div className="header-right">
          {/* Sign-in Button */}
          <button className="signin-button" onClick={handleRedirect}>
            {user ? 'Dashboard' : 'Free Sign Up'}
          </button>
        </div>
      </div>

      {/* Header Row */}
      <div className="faq-header-row">
        <div className="faq-left">
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className="faq-right">
          <div className="search-bar">
            <input
              type="search"
              placeholder="Write your question here"
              value={searchTerm}
              onChange={handleSearch}
              className="faq-search"
            />
          </div>
        </div>
      </div>

      {/* Content Row */}
      <div className="faq-content-row">
        <div className="faq-left">
          <aside className="faq-sidebar">
            <ul>
              {faqs.map((category, index) => (
                <li
                  key={index}
                  className={activeCategory === category.category ? 'active' : ''}
                  onClick={() => handleCategoryClick(category.category)}
                >
                  {category.category}
                </li>
              ))}
            </ul>
          </aside>
        </div>
        <div className="faq-right">
          <main className="faq-main">
            {searchTerm ? (
              filteredFaqs.length > 0 ? (
                filteredFaqs.map((category, catIndex) => (
                  <div key={catIndex} className="faq-section">
                    <h2>{category.category}</h2>
                    {category.faqs.map((faq, faqIndex) => (
                      <div
                        key={faqIndex}
                        className={`faq-item ${activeFaq === faqIndex ? 'active' : ''}`}
                      >
                        <h3 onClick={() => handleFaqClick(faqIndex)}>
                          {faq.question}
                          <span className="arrow">{activeFaq === faqIndex ? '▲' : '▼'}</span>
                        </h3>
                        {activeFaq === faqIndex && (
                          <div className="faq-answer">
                            <p>{faq.answer}</p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <p className="no-results">No FAQs match your search.</p>
              )
            ) : activeCategory ? (
              faqs
                .find((cat) => cat.category === activeCategory)
                .faqs.map((faq, index) => (
                  <div key={index} className={`faq-item ${activeFaq === index ? 'active' : ''}`}>
                    <h3 onClick={() => handleFaqClick(index)}>
                      {faq.question}
                      <span className="arrow">{activeFaq === index ? '▲' : '▼'}</span>
                    </h3>
                    {activeFaq === index && (
                      <div className="faq-answer">
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))
            ) : (
              <div className="welcome-message">
                <p>Select a category from the sidebar to view FAQs.</p>
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
