import React, { useState, useEffect } from 'react';
import './TopPredictionsPage.css'; // Custom CSS for the Top Predictions Page

const TopPredictionsPage = () => {
  const [data, setData] = useState([]); // State for storing the fetched data
  const [filteredData, setFilteredData] = useState([]); // State for storing filtered data
  const [loading, setLoading] = useState(true); // State for handling loading state
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [sortOrder, setSortOrder] = useState(''); // State to track sort order ('latest' or 'oldest')
  const [currentPage, setCurrentPage] = useState(1); // State for the current page
  const itemsPerPage = 8; // Number of items to show per page

  // Fetch data from the predictions.json file
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:3002/getPredictions'); // Adjust the endpoint as necessary
        const result = await response.json();
        setData(result);
        setFilteredData(result);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching predictions data", err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle search term change
  useEffect(() => {
    const searchResults = data.filter((entry) =>
      entry.ticker.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(searchResults);
    setCurrentPage(1); // Reset to the first page on search
  }, [searchTerm, data]);

  // Handle sorting by latest or oldest
  useEffect(() => {
    if (sortOrder) {
      const sortedData = [...filteredData].sort((a, b) => {
        const dateA = new Date(a.researchDate);
        const dateB = new Date(b.researchDate);
        return sortOrder === 'latest' ? dateB - dateA : dateA - dateB;
      });
      setFilteredData(sortedData);
    }
  }, [sortOrder, filteredData]);

  // Calculate the current page data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  // Handle pagination controls
  const handleNextPage = () => {
    if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="top-predictions-page-container">
      {/* Minimal Headline */}
      <h3 className="min-headline"></h3>

      <div className="top-predictions-container">
        {/* Inside the box: Header, Search, Filter */}
        <div className="header-row">
          <h2 className="box-headline">Top 10 Predictions from yesterday</h2>

          <div className="right-side-controls">
            <input
              type="text"
              className="search-bar"
              placeholder="Search by ticker symbol"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Dropdown for sorting */}
            <select
              className="sort-dropdown"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="latest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>

        <div className="top-predictions-table-container">
          <table className="top-predictions-table">
            <thead>
              <tr>
                <th>Ticker</th>
                <th>Overall Accuracy</th>
                <th>Most Recent Prediction</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>Loading data...</td>
                </tr>
              ) : currentData.length === 0 ? (
                <tr>
                  <td colSpan="3" style={{ textAlign: 'center' }}>No predictions found</td>
                </tr>
              ) : (
                currentData.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.ticker}</td>
                    <td>{entry.overallAccuracy}</td>
                    <td>{entry.mostRecentPrediction}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          <span>Showing {indexOfFirstItem + 1} to {indexOfLastItem < filteredData.length ? indexOfLastItem : filteredData.length} of {filteredData.length} entries</span>
          <div className="pagination-controls">
            <button className="page-btn" onClick={handlePrevPage} disabled={currentPage === 1}>&lt;</button>
            <button className="page-btn active">{currentPage}</button>
            <button className="page-btn" onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}>&gt;</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPredictionsPage;
