import React, { useState, useEffect } from 'react';
import './ScreenersPage.css'; // Custom CSS for the Screeners Page

const ScreenersPage = () => {
  const [winnersData, setWinnersData] = useState([]);
  const [losersData, setLosersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('winners');
  const [filters, setFilters] = useState({
    ticker: '',
    priceChangePercentMin: '',
    priceChangePercentMax: '',
    volumeMin: '',
    volumeMax: '',
    confidenceMin: '',
    confidenceMax: '',
  });

  useEffect(() => {
    const fetchScreenersData = async () => {
      try {
        const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';
        const response = await fetch(`${baseURL}/screeners`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();

        setWinnersData(result.predicted_top_winners || []);
        setLosersData(result.predicted_top_losers || []);
      } catch (err) {
        console.error("Error fetching screeners data", err);
      } finally {
        setLoading(false);
      }
    };

    fetchScreenersData();
  }, []);

  useEffect(() => {
    // Apply filters based on the filters state
    const dataToFilter = activeTab === 'winners' ? winnersData : losersData;
    const filtered = dataToFilter.filter((item) => {
      const matchesTicker = filters.ticker ? item.ticker.toLowerCase().includes(filters.ticker.toLowerCase()) : true;
      const matchesPriceChangePercent =
        (!filters.priceChangePercentMin || item.price_change_percent_actuals >= parseFloat(filters.priceChangePercentMin)) &&
        (!filters.priceChangePercentMax || item.price_change_percent_actuals <= parseFloat(filters.priceChangePercentMax));
      const matchesVolume =
        (!filters.volumeMin || item.volume >= parseFloat(filters.volumeMin)) &&
        (!filters.volumeMax || item.volume <= parseFloat(filters.volumeMax));
      const matchesConfidence =
        (!filters.confidenceMin || item.confidence >= parseFloat(filters.confidenceMin)) &&
        (!filters.confidenceMax || item.confidence <= parseFloat(filters.confidenceMax));
      return matchesTicker && matchesPriceChangePercent && matchesVolume && matchesConfidence;
    });
    setFilteredData(filtered);
  }, [activeTab, filters, winnersData, losersData]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const computeRangeConfidence = (confidence) => {
    if (confidence < 30) return 'Low';
    if (confidence >= 30 && confidence <= 50) return 'Med';
    return 'High';
  };

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    // Use the components of the date to format it explicitly
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth is zero-indexed
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  const formatVolume = (volume) => {
    return volume ? volume.toLocaleString() : '-';
  };

  return (
    <div className="screeners-page-container">
      <div className="tabs">
        <div className={`tab ${activeTab === 'winners' ? 'active' : ''}`} onClick={() => handleTabClick('winners')}>
          Predicted Winners
        </div>
        <div className={`tab ${activeTab === 'losers' ? 'active' : ''}`} onClick={() => handleTabClick('losers')}>
          Predicted Losers
        </div>
      </div>

      {/* <div className="screeners-filter-container">
        <h3>Filter Stocks</h3>
        <div className="screeners-filters">
          <div className="screeners-filter-group">
            <label>Ticker</label>
            <input
              type="text"
              name="ticker"
              value={filters.ticker}
              onChange={handleFilterChange}
              placeholder="Search by ticker"
            />
          </div>
          <div className="screeners-filter-group">
            <label>Price Change %</label>
            <input
              type="number"
              name="priceChangePercentMin"
              value={filters.priceChangePercentMin}
              onChange={handleFilterChange}
              placeholder="Min"
            />
            <input
              type="number"
              name="priceChangePercentMax"
              value={filters.priceChangePercentMax}
              onChange={handleFilterChange}
              placeholder="Max"
            />
          </div>
          <div className="screeners-filter-group">
            <label>Volume</label>
            <input
              type="number"
              name="volumeMin"
              value={filters.volumeMin}
              onChange={handleFilterChange}
              placeholder="Min"
            />
            <input
              type="number"
              name="volumeMax"
              value={filters.volumeMax}
              onChange={handleFilterChange}
              placeholder="Max"
            />
          </div>
          <div className="screeners-filter-group">
            <label>Confidence (%)</label>
            <input
              type="number"
              name="confidenceMin"
              value={filters.confidenceMin}
              onChange={handleFilterChange}
              placeholder="Min"
            />
            <input
              type="number"
              name="confidenceMax"
              value={filters.confidenceMax}
              onChange={handleFilterChange}
              placeholder="Max"
            />
          </div>
        </div>
      </div> */}

      <div className="table-container">
        <table className="screeners-table">
          <thead>
            <tr>
              <th>Ticker</th>
              <th>Prediction Date</th>
              <th>Predicted Value</th>
              <th>{activeTab === 'winners' ? 'Predicted Gain $' : 'Predicted Loss $'}</th>
              <th>{activeTab === 'winners' ? 'Predicted Gain %' : 'Predicted Loss %'}</th>
              <th>Range Confidence</th>
              {/* <th>Date of Actuals</th> */}
              <th>Prev. Close</th>
              <th>Prev. Change %</th>
              <th>Prev. Volume</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="10" style={{ textAlign: 'center' }}>Loading data...</td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td colSpan="10" style={{ textAlign: 'center' }}>No data available</td>
              </tr>
            ) : (
              filteredData.map((entry, index) => (
                <tr key={index}>
                  <td className="left-aligned">
                      {entry.ticker ? (
                        <a href={`https://marketcrunch.ai/analyze?t=${entry.ticker}`} target="_blank" rel="noopener noreferrer">
                          {entry.ticker}
                        </a>
                      ) : '-'}</td>
                  <td>{formatDate(entry.prediction_date)}</td>
                  <td className="numeric-column">${entry.predicted_close?.toFixed(2) || '-'}</td>
                  <td className="numeric-column">{entry.predicted_gain_value?.toFixed(2) || '-'}</td>
                  <td className="numeric-column">{entry.predicted_gain_percent?.toFixed(2) || '-'}%</td>
                  <td>{computeRangeConfidence(entry.confidence)}</td>
                  {/* <td>{formatDate(entry.date_of_actuals)}</td> */}
                  <td className="numeric-column">${entry.actual_close?.toFixed(2) || '-'}</td>
                  <td className="numeric-column">{entry.price_change_percent_actuals?.toFixed(2) || '-'}%</td>
                  <td className="numeric-column">{formatVolume(entry.volume)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScreenersPage;