import React from 'react';
import styles from './AimSection.module.css'; // Use CSS Modules
import aimImg from '../assets/images/aim-img.png'; // Ensure the path is correct

const AimSection = () => {
  return (
    <div id="home" className={styles.aimSection}>
      <div className={styles.searchRow}>
        <div className={styles.leftColumn}>
          <h3 className={styles.aimText}>
            <span>Advanced neural network analyzes</span><br />
            <span>over millions of data points to find</span><br />
            <span>causality</span>
          </h3>
        </div>
        <div className={styles.rightColumn}>
          <img src={aimImg} alt="Neural Network Analysis" className={styles.aimImg} />
        </div>
      </div>
    </div>
  );
};

export default AimSection;
