import { useNavigate } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import './Dashboard.css';
import './AccountPage.css';
import { auth } from '../firebase-config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';

const AccountPage = ({ setCredits }) => {
    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [user, setUser] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]); 
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';
    const dropdownRef = useRef(null);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  
    // Handle sorting by latest or oldest
    useEffect(() => {
      if (sortOrder) {
        const sortedData = [...filteredData].sort((a, b) => {
          const dateA = new Date(a.researchDate);
          const dateB = new Date(b.researchDate);
          return sortOrder === 'latest' ? dateB - dateA : dateA - dateB;
        });
        setFilteredData(sortedData);
      }
    }, [sortOrder, filteredData]);

    useEffect(() => {
      if (!user) return;
      const fetchCredits = async () => {
        const userId = localStorage.getItem('uid');
        const response = await fetch(`${baseURL}/get-credits`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userId}:${user.uid}`
          },
        });
    
        if (!response.ok) {
          throw new Error(`Failed to fetch credits. Status: ${response.status}`);
        };
    
        const result = await response.json();
        localStorage.setItem('credits', result.credits_available);
        setCredits(result.credits_available);
      };
      fetchCredits();
    }, [user]);

      // Fetch history data for the logged-in user
  const fetchData = async (userId) => {
    try {
      const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';

      const response = await axios.get(`${baseURL}/get-payment-history`, {
        params: { uid: userId }, // Ensure 'user_id' is sent as the correct parameter
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const fetchedData = response.data;
      // console.log(fetchedData)

      // Ensure fetchedData is an array before setting state
      if (fetchedData && Array.isArray(fetchedData.payment_history)) {
        setData(fetchedData.payment_history);
        setFilteredData(fetchedData.payment_history);
        if (fetchedData.payment_history.length > 0) {
          setIsSubscribed(true);
        }
      } else {
        setData([]);
        setFilteredData([]);
        setIsSubscribed(false);
        setError('Invalid data format received');
      }

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch history data. Please try again.');
      setLoading(false);
    }
  };
  
    // Handle pagination controls
    const handleNextPage = () => {
      if (currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const handlePrevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    const handleLogout = async () => {
        try {
            localStorage.removeItem('uid');
            localStorage.removeItem('predictionData');
            localStorage.clear() 
            await signOut(auth);
            navigate('/');
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                fetchData(localStorage.getItem('databaseUid'));
            } else {
                setUser(null); 
            }
            });
        return () => unsubscribe();
    }, [user]);

    return (
            <div className="dashboard-search-container accounts-page">
                <h2 className="profile-header">Profile</h2>
                <div className='profile-info-container'>
                  <span>Name</span>
                  <div>
                    <input type="text" value={user ? user.displayName : 'No user found'} disabled></input>
                    <span style={{color: 'gray', fontWeight: '400', marginLeft: '1em', fontSize: 15}}><em>as it appears on Google</em></span>
                  </div>
                </div>
                <div className='profile-info-container'>
                  <span>Email</span>
                  <div>
                    <input type="text" value={user ? user.email : 'No user found'} disabled></input>
                    <span style={{color: 'gray', fontWeight: '400', marginLeft: '1em', fontSize: 15}}><em>as it appears on Google</em></span>
                  </div>
                </div>
                {/* <div className='profile-info-container'>
                  <span>Subscription</span>
                  <span>{isSubscribed ? 'Yes' : 'No'}</span>
                  <button disabled={!isSubscribed}>Cancel Subscription</button>
                </div> */}

                <div className="top-predictions-container">
                  {/* Inside the box: Header, Search, Filter */}
                  <div className="header-row">
                    <h2 className="box-headline">Payment History</h2>

                    <div className="right-side-controls">
                      {/* Dropdown for sorting */}
                      {/* <select
                        className="sort-dropdown"
                        value={sortOrder}
                        onChange={handleSortChange}
                      >
                        <option value="latest">Newest</option>
                        <option value="oldest">Oldest</option>
                      </select> */}
                    </div>
                  </div>

                  <div className="top-predictions-table-container">
                    <table className="top-predictions-table">
                      <thead>
                        <tr>
                          <th>Payment Date</th>
                          <th>Total</th>
                          <th>Credits Received</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'center' }}>Loading data...</td>
                          </tr>
                        ) : currentData.length === 0 ? (
                          <tr>
                            <td colSpan="3" style={{ textAlign: 'center' }}>No payments found</td>
                          </tr>
                        ) : (
                          currentData.map((entry, index) => (
                            <tr key={index}>
                              <td>{entry.payment_timestamp}</td>
                              <td>{entry.payment_amount}</td>
                              <td>{entry.credits_received}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination */}
                  <div className="pagination">
                    <span>Showing {indexOfFirstItem + 1} to {indexOfLastItem < filteredData.length ? indexOfLastItem : filteredData.length} of {filteredData.length} entries</span>
                    <div className="pagination-controls">
                      <button className="page-btn" onClick={handlePrevPage} disabled={currentPage === 1}>&lt;</button>
                      <button className="page-btn active">{currentPage}</button>
                      <button className="page-btn" onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredData.length / itemsPerPage)}>&gt;</button>
                    </div>
                  </div>
                </div>

            </div>      
  )
}

export default AccountPage;
