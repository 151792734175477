import React, { useState, useEffect } from "react";
import './TestimonialSection.css';

const testimonialsData = [
  {
      text: "After many months of unsuccessful trading, I came across MarketCrunch and it really provided deep insights into near term price movements.",
      author: "Julio S."
  },
  {
      text: "We live in the age of machines: hedge-funds and institutions dictate price movements. MarketCrunch helps the little guys by using machines to provide simple insights.",
      author: "K. Coy"
  },
  {
      text: "Exceptional insights and outstanding support. MarketCrunch helps the little guys.",
      author: "S. Varma"
  },
  {
      text: "For me, MarketCrunch revolutionized the way I approach trading. Now I come here first, before hitting the Trade button",
      author: "Client"
  },
  {
      text: "“A game-changer for small investors. MarketCrunch leveled the playing field.”",
      author: "J.P."
  },
  {
      text: "“The user interface is intuitive and the data is spot on. Highly recommend MarketCrunch.”",
      author: "A. Powar"
  },
  // Add more testimonials as needed
];

const TestimonialSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = testimonialsData.length > 3 ? Math.ceil(testimonialsData.length / 2) : 1;

    // Automatic scrolling every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
        }, 5000);
        return () => clearInterval(interval);
    }, [totalSlides]);

    // Calculate the range of testimonials to display for the current slide
    const startIndex = currentSlide * 2;
    const endIndex = startIndex + 3 > testimonialsData.length ? testimonialsData.length : startIndex + 3;
    const currentTestimonials = testimonialsData.slice(startIndex, endIndex);

    return (
        <div id="testimonials" className="testimonials-partnerships-section">
            <div className="testimonials-container">
                <div className="testimonials-heading">
                    <h2>Testimonials</h2>
                </div>
                <div className="carousel-wrapper">
                    <div className="carousel-content">
                        {currentTestimonials.map((testimonial, index) => (
                            <div className={`testimonial-column ${index === 2 ? 'partial' : ''}`} key={startIndex + index}>
                                <div className="testimonial">
                                    <p>"{testimonial.text}"</p>
                                    <p className="author">- {testimonial.author}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="carousel-indicators">
                    {Array.from({ length: totalSlides }).map((_, index) => (
                        <span
                            key={index}
                            className={`indicator-dot ${index === currentSlide ? 'active' : ''}`}
                            onClick={() => setCurrentSlide(index)}
                            aria-label={`Go to testimonials slide ${index + 1}`}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestimonialSection;
