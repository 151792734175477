export const logUserActivity = async (userId, pageType) => {
    try {
      const ip = await fetch("https://api.ipify.org?format=json").then(res => res.json()).then(data => data.ip); // Fetch client IP

      const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV'
      ? 'http://127.0.0.1:5000'
      : 'https://flask-backend-52245432644.us-central1.run.app';


      const response = await fetch(`${baseURL}/log-activity`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          ipAddress: ip,
          pageType,
        }),
      });
      if (!response.ok) {
        console.error("Failed to log activity");
      }
    } catch (error) {
      console.error("Error logging activity:", error);
    }
  };