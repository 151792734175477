import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TeamSection.css';
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

const TeamSection = () => {
  const [user, setUser] = useState(null); // Store current user
  const navigate = useNavigate();

  const handleRedirect = () => {
    user ? navigate('/dashboard') : navigate('/login');
  };

  // Check if user is logged in
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <div id="team" className="team-section">
      <h2>Our Team</h2>
      <div className="team-container">
        <div className="team-content">
          <div className="aligned-text">
            <p>
              We believe every investor deserves fast, reliable research—not fragmented sources. Whether you trade on fundamentals, technicals, or macro strategies, sound analysis should be at the heart of your decisions.
            </p>
            <p>
              We understand you don't always have the time or tools. So, we built a powerful AI-driven engine that delivers hedge-fund-grade insights in seconds. Backed by experts from Amazon, Pinterest, and Credit Suisse and developed by graduates from UC Berkeley, Wharton, Carnegie Mellon, and UCLA, our platform simplifies complex analysis for quick, actionable results.
            </p>
            <p>
              No more wasting time staring at the screens. MarketCrunch AI gives you the research edge you need — in simple, intuitive format.
            </p>
          </div>
          <br />
          <div className="centered-text">
            <p>
              Get access to cutting-edge AI insights to help you make more informed decisions, quickly.
            </p>
            <button className="footer-signup" onClick={handleRedirect}>{user ? 'Dashboard' : 'Free Sign Up'}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
