import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet  } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import TrendingPage from './components/TrendingPage';
import TopPredictionsPage from './components/TopPredictionsPage';
import HistoryPage from './components/HistoryPage';
import ProgressPage from './components/ProgressPage';
import TestimonialSection from './components/TestimonialSection';
import PaymentPage from './components/PaymentPage';
import AccountPage from './components/AccountPage';
import FAQPage from './components/FAQPage';
import UpgradePopup from './components/UpgradePopup';
import ScreenersPage from './components/ScreenersPage';

function DashboardWrapper() {
  return <Dashboard><Outlet /></Dashboard>;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/analyze" element={<ProgressPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard/*" element={<DashboardWrapper />}>
          <Route path="screeners" element={<ScreenersPage />} />
        </Route>
        <Route path="/progress" element={<ProgressPage />} />
        {/* <Route path="/trending" element={<TrendingPage />} /> */}
        <Route path="/toppredictions" element={<TopPredictionsPage />} />
        <Route path="/testimonials" element={<TestimonialSection/>}/>
        {/* <Route path="/history" element={<HistoryPage />} /> */}
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/faqs" element={<FAQPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/faqs" element={<FAQPage />} />
        {/* <Route path="/screeners" element={<ScreenersPage />} /> */}
        <Route path="/testing" element={<UpgradePopup isOpen={true} />} />
      </Routes>
    </Router>
  );
}

export default App;
