// Terms.jsx
import React, { useEffect, useRef } from 'react';
import './Terms.css';

const Terms = ({ isOpen, onClose, termsButtonRef }) => {
  const closeButtonRef = useRef(null);

  // Handle Escape key to close the popup
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  // Prevent background scrolling when the popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Focus the close button for accessibility
      if (closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // Return focus to the terms button when closing
  const handleClose = () => {
    onClose();
    if (termsButtonRef && termsButtonRef.current) {
      termsButtonRef.current.focus();
    }
  };

  if (!isOpen) return null;

  return (
    <div className='terms'>
      <div className="popup-overlay" onClick={handleClose}>
        <div className="popup-content" onClick={(e) => e.stopPropagation()}>
          <button
            className="close-button"
            onClick={handleClose}
            ref={closeButtonRef}
            aria-label="Close"
          >
            &times;
          </button>
          <h2>Terms and Conditions</h2>
          <h3>1. Important Terms</h3>

          <p>
            <h4>1.1 </h4>
            These Terms and Conditions are between MarketCrunch AI, Inc., a Delaware C-Corp, and you ("You"), and they govern your use of MarketCrunch AI ("Service" or "Platform"). If you are using the Service on behalf of an organization, you represent that you are authorized to bind that organization to these Terms.
          </p>
          <br/>

          <p>
            <h4>1.2 </h4>
            We may update these Terms by posting updated versions on our website. Continued use of the Platform after any updates constitutes your acceptance of the new Terms. Updates are not retroactive unless required by law.
          </p>
          <br/>

          <p>
            <h4>1.3 </h4>
            MarketCrunch AI is not a financial advisory service and does not provide personalized investment advice. All insights and predictions provided are AI-generated for informational purposes only and may contain inaccuracies or omissions.
          </p>
          <br/>

          <h3>2. Use of Service</h3>

          <p>
            <h4>2.1 </h4>
            You are granted a non-exclusive, non-transferable, and revocable right to use the Platform. User accounts and access credentials are personal and must not be shared. Unauthorized access must be reported immediately.
          </p>
          <br/>

          <p>
            <h4>2.2 </h4>
            You agree not to:
          </p>
          <ul>
            <li>Use the Platform in ways that violate intellectual property or data privacy rights.</li>
            <li>Reverse-engineer or extract the source code of the Service.</li>
            <li>Use the Platform in jurisdictions where the Service is restricted or embargoed.</li>
          </ul>
          <br/>

          <p>
            <h4>2.3 </h4>
            By submitting any feedback, you grant MarketCrunch AI the right to use it to improve our Service. MarketCrunch AI will not use such feedback to identify or disclose your confidential information or data.
          </p>
          <br/>

          <p>
            <h4>2.4 </h4>
            The Platform may interface with third-party services, such as payment processors. We are not responsible for the performance of such third-party providers.
          </p>
          <br/>

          <h3>3. Content and Data</h3>

          <p>
            <h4>3.1 </h4>
            You may provide inputs (stock tickers) to the Service and receive outputs (AI-generated predictions). The ownership of outputs rests with you, subject to compliance with these Terms.
          </p>

          <p>
            <h4>3.2 </h4>
            MarketCrunch AI may collect anonymized usage data to improve the Service. We will not share your personal data with third parties except as required by law or in aggregated, anonymized form.
          </p>
          <br/>

          <p>
            <h4>3.3 </h4>
            You retain all rights to your data, but you grant us a license to process it solely to provide the Service.
          </p>
          
          <h3>4. Subscription Plans and Payments</h3>

          <p>
            <h4>4.1 </h4>
            Predictions on the Platform require credits, with one prediction costing one credit. Free-tier users receive weekly credits, which reset every [insert interval] without rollover. Additional credits may be purchased.
          </p>
          <br/>

          <p>
            <h4>4.2 Subscription Tiers: </h4>
            <ul>
              <li><strong>Free Tier:</strong> Limited weekly predictions with basic features.</li>
              <li><strong>Pro Tier:</strong> Paid tier with additional predictions and premium insights.</li>
              <li><strong>Investor Tier:</strong> Unlimited predictions with full feature access.</li>
            </ul>
          </p>
          <br/>

          <p>
            <h4>4.3 </h4>
            Payments are non-refundable, except as required by law. Subscription renewals occur automatically unless canceled.
          </p>
          <br/>
          
          <h3>5. Warranty and Disclaimer</h3>

          <p>
            <h4>5.1 Data Accuracy and Use: </h4>
            MarketCrunch AI is provided on an as-is and as-available basis. We make no warranties, expressed or implied, regarding the accuracy, completeness, or reliability of the predictions or insights offered by the platform. MarketCrunch AI disclaims any warranties related to merchantability, fitness for a particular purpose, or non-infringement.
          </p>

          <p>
            <h4>5.2 User Responsibility: </h4>
            MarketCrunch AI provides informational content only. You acknowledge that the platform’s outputs may contain errors, omissions, or inaccuracies and should not be relied upon for making investment decisions. It is your responsibility to seek independent financial advice before acting on any output.
          </p>

          <p>
            <h4>5.3 Service Availability: </h4>
            MarketCrunch AI does not guarantee uninterrupted service. You acknowledge that technical issues may result in temporary downtime, and we will not be liable for any interruptions or disruptions.
          </p>

          <h3>6. Limitation of Liability</h3>

          <p>
            <h4>6.1 Indirect or Consequential Damages: </h4>
            In no event will either party be liable for indirect, incidental, special, or consequential damages, including but not limited to lost profits, revenue, business opportunities, or data, even if such damages were foreseeable.
          </p>

          <p>
            <h4>6.2 Liability Cap: </h4>
            MarketCrunch AI’s total liability for any claims or damages, regardless of the nature of the claim, will not exceed the greater of $12,000 or the amount you paid to MarketCrunch AI within the previous 12 months. This limitation applies to all causes of action, whether arising in contract, tort (including negligence), or otherwise.
          </p>

          <p>
            <h4>6.3 No Fiduciary Relationship: </h4>
            MarketCrunch AI is not responsible for any investment losses or other outcomes resulting from reliance on the platform’s outputs. Your use of the platform is at your own risk.
          </p>

          <h3>7. Termination</h3>

          <p>
            <h4>7.1 </h4>
            These Terms remain in effect until terminated. You may terminate by discontinuing use of the Service. We reserve the right to terminate your access at any time if these Terms are violated.
          </p>

          <p>
            <h4>7.2 </h4>
            Upon termination, your access will cease, and you must delete any confidential information obtained from the Platform.
          </p>

          <h3>8. General Terms</h3>

          <p>
            <h4>8.1 Assignment: </h4>
            Neither party may assign these Terms without prior written consent from the other, except in connection with a merger, acquisition, or sale of substantially all of the assigning party's assets.
          </p>

          <p>
            <h4>8.2 Confidentiality: </h4>
            Both parties will treat any disclosed information as confidential and use the same care as they would with their own confidential information. If legally required to disclose confidential information, the receiving party will notify the disclosing party in advance, if permitted, and cooperate to obtain protective orders as necessary.
          </p>

          <p>
            <h4>8.3 Use of Subcontractors: </h4>
            MarketCrunch AI may engage subcontractors or third-party service providers to assist in delivering the Service. We remain responsible for any such third parties' compliance with these Terms.
          </p>

          <p>
            <h4>8.4 Force Majeure: </h4>
            Neither party will be liable for delays or failure to perform resulting from events beyond reasonable control, including acts of God, pandemics, government actions, labor disputes, or other disruptions beyond their control.
          </p>

          <p>
            <h4>8.5 Notices: </h4>
            All legal notices must be provided in writing (email notices are sufficient). Notices to MarketCrunch AI should be sent to [Insert Contact Email], and we will send notices to the email associated with your account.
          </p>

          <p>
            <h4>8.6 Compliance with Laws: </h4>
            You agree to comply with all applicable U.S. laws and regulations, including but not limited to export control and anti-terrorism laws. You represent that you are not located in or acting on behalf of any embargoed country or restricted entity.
          </p>

          <h3>9. Dispute Resolution and Governing Law</h3>

          <p>
            <h4>9.1 Governing Law: </h4>
            These Terms will be governed by the laws of Delaware, USA, without reference to conflict-of-law principles. The United Nations Convention on Contracts for the International Sale of Goods does not apply.
          </p>

          <p>
            <h4>9.2 Arbitration Clause: </h4>
            All disputes will be resolved through binding arbitration administered by the American Arbitration Association (AAA). Arbitration will occur in Delaware, and all proceedings will be conducted in English.
          </p>

          <p>
            <h4>9.3 Exceptions to Arbitration: </h4>
            Either party may seek equitable relief, such as an injunction, in a court of competent jurisdiction to prevent unauthorized use of confidential information or intellectual property.
          </p>

          <p>
            <h4>9.4 Class Action Waiver: </h4>
            All claims must be pursued on an individual basis. Class actions or collective claims are not permitted under these Terms.
          </p>

          <p>
            <h4>9.5 Survival of Terms: </h4>
            Sections related to confidentiality, liability, indemnification, and governing law will survive the termination of these Terms.
          </p>

          <p>
            <h4>9.6 Entire Agreement: </h4>
            These Terms constitute the complete agreement between the parties, superseding all previous agreements, representations, or understandings, whether written or oral.
          </p>

          <h3>10. Communication and Marketing</h3>

          <p>
            <h4>10.1 </h4>
            By signing up for MarketCrunch AI, you consent to receive transactional emails related to the platform, including account updates, usage notifications, and service-related announcements.
          </p>

          <p>
            <h4>10.2 </h4>
            You may also receive promotional emails regarding new features, updates, or offers. You can opt out of promotional emails at any time by following the unsubscribe instructions provided in each email.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
