import React from 'react';
import styles from './ModelSection.module.css'; // Use CSS Modules
import modelImg from '../assets/images/model-img.png'; // Ensure the path is correct

const ModelSection = () => {
  return (
    <div id="home" className={styles.modelSection}>
      <div className={styles.searchRow}>
        <div className={styles.leftColumn}>
          <h3 className={styles.modelText}>
            <span>Customized AI-powered Analysis</span><br />
            <span>with 1-click</span>
          </h3>
        </div>
        <div className={styles.rightColumn}>
          <img src={modelImg} alt="Neural Network Analysis" className={styles.modelImg} />
        </div>
      </div>
    </div>
  );
};

export default ModelSection;
