// Disclaimer.jsx
import React, { useEffect, useRef } from 'react';
import './Disclaimer.css';

const Disclaimer = ({ isOpen, onClose, disclaimerButtonRef }) => {
  const closeButtonRef = useRef(null);

  // Handle Escape key to close the popup
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscape);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  // Prevent background scrolling when the popup is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      // Focus the close button for accessibility
      if (closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // Return focus to the disclaimer button when closing
  const handleClose = () => {
    onClose();
    if (disclaimerButtonRef && disclaimerButtonRef.current) {
      disclaimerButtonRef.current.focus();
    }
  };

  if (!isOpen) return null;

  return (
    <div className='disclaimer'>
        <div className="popup-overlay" onClick={handleClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <button
          className="close-button"
          onClick={handleClose}
          ref={closeButtonRef}
          aria-label="Close"
        >
          &times;
        </button>
        <h2>Disclaimer</h2>
        <p>
          This is for informational purposes only as MarketCrunch AI is not registered as a securities broker-dealer or an investment adviser.
          No information herein is intended as securities brokerage, investment, tax, accounting, or legal advice, as an offer or solicitation
          of an offer to sell or buy, or as an endorsement, recommendation, or sponsorship of any company, security, or fund. MarketCrunch AI
          cannot and does not assess, verify, or guarantee the adequacy, accuracy, or completeness of any information, the suitability or
          profitability of any particular investment, or the potential value of any investment or informational source. The reader bears
          responsibility for his/her own investment research and decisions, should seek the advice of a qualified securities professional
          before making any investment, and investigate and fully understand any and all risks before investing. MarketCrunch AI in no way
          warrants the solvency, financial condition, or investment advisability of any of the securities mentioned in communications or
          websites. In addition, MarketCrunch AI accepts no liability whatsoever for any direct or consequential loss arising from any use
          of this information. This information is not intended to be used as the basis of any investment decision, should it be construed as
          advice designed to meet the investment needs of any particular investor. Past performance is not necessarily indicative of future
          returns.
        </p>
      </div>
    </div>
    </div>
  );
};

export default Disclaimer;
