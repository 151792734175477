import React, { useState, useEffect } from 'react'; 
import { useNavigate, useLocation } from 'react-router-dom'; 
import axios from 'axios'; // Import axios for API calls
import './HistoryPage.css'; // Custom CSS for the History Page
import { auth } from '../firebase-config';
import { onAuthStateChanged } from 'firebase/auth';

const HistoryPage = ({ updateActiveTab, handleDisplayData }) => {
  const [data, setData] = useState([]); // State for storing the fetched data
  const [filteredData, setFilteredData] = useState([]); // State for storing filtered data
  const [loading, setLoading] = useState(true); // State for handling loading state
  const [error, setError] = useState(null); // State for handling errors
  const [user, setUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [sortOrder, setSortOrder] = useState(''); // State to track sort order ('latest' or 'oldest')
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(user);
        } else {
            setUser(null); 
        }
        });
    return () => unsubscribe();
}, [user]);


  useEffect(() => {
    // Fetch user ID from localStorage (or another source where you store the logged-in user info)
    const userId = localStorage.getItem('databaseUid'); 

    if (userId) {
      fetchData(userId);
    } else {
      setError('User is not logged in');
      setLoading(false);
    }
  }, []);

  const handleViewPrediction = async (prediction_id) => {
    updateActiveTab('analyze');
    navigate(`/dashboard?pid=${prediction_id}`);
  };
  


  // Fetch history data for the logged-in user
  const fetchData = async (userId) => {
    try {
      const baseURL = process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? 'http://127.0.0.1:5000' : 'https://flask-backend-52245432644.us-central1.run.app';

      const response = await axios.get(`${baseURL}/history`, {
        params: { user_id: userId }, // Ensure 'user_id' is sent as the correct parameter
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const fetchedData = response.data;
      // console.log(fetchedData)

      // Ensure fetchedData is an array before setting state
      if (fetchedData && Array.isArray(fetchedData.history)) {
        setData(fetchedData.history);
        setFilteredData(fetchedData.history);
        console.log(fetchedData.history);
      } else {
        setData([]);
        setFilteredData([]);
        setError('Invalid data format received');
      }

      setLoading(false);
    } catch (err) {
      setError('Failed to fetch history data. Please try again.');
      setLoading(false);
    }
  };

  // Handle search term change
  useEffect(() => {
    const searchResults = data.filter((entry) =>
      entry.ticker.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(searchResults);
  }, [searchTerm, data]);

  // Handle sorting by latest or oldest
  useEffect(() => {
    if (sortOrder) {
      const sortedData = [...filteredData].sort((a, b) => {
        const dateA = new Date(a.researchDate);
        const dateB = new Date(b.researchDate);
        return sortOrder === 'latest' ? dateB - dateA : dateA - dateB;
      });
      setFilteredData(sortedData);
    }
  }, [sortOrder, filteredData]);
  return (
    <div className="history-page-container">
      {/* Minimal Headline */}
      <h3 className="min-headline">My Research History</h3>

      <div className="history-container">
        {/* Inside the box: Header, Search, Filter */}
        <div className="header-row">

          <div className="right-side-controls">
            <input
              type="text"
              className="search-bar"
              placeholder="Search by ticker symbol..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Dropdown for sorting with "Sort By" as placeholder */}
            <select
              className="sort-dropdown"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="" disabled>Sort By</option> {/* Placeholder option */}
              <option value="latest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>

        <div className="history-table-container">
          <table className="history-table">
            <thead>
              <tr>
                <th>Research Date</th>
                <th>Ticker</th>
                <th>Prediction Date</th>
                <th>Prediction Value</th>
                {/* <th>Actual Value</th> */}
                <th>AI Analysis</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: 'center' }}>Loading data...</td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: 'center' }}>{error}</td>
                </tr>
              ) : Array.isArray(filteredData) && filteredData.length === 0 ? (
                <tr>
                  <td colSpan="4" style={{ textAlign: 'center' }}>No history found</td>
                </tr>
              ) : (
                Array.isArray(filteredData) && filteredData.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.research_date}</td>
                    <td>{entry.ticker}</td>
                    <td>{entry.prediction_date}</td>
                    <td>{entry.prediction_value}</td>
                    {/* <td>Coming soon...</td> */}
                    <td><button className="view-prediction" onClick={() => handleViewPrediction(entry.prediction_id)}>View</button></td>                    
                    
                       {/* <th>Actual Value</th>
                <th>Actual Open-Close</th>
                <th>Accuracy</th>
                <th>Prediction In Range</th> */}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="pagination">
          <span>Showing data 1 to {filteredData.length || 0} entries</span>
          <div className="pagination-controls">
            <button className="page-btn" disabled>&lt;</button>
            <button className="page-btn active">1</button>
            <button className="page-btn" disabled>&gt;</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryPage;
