// MobileMenu.jsx
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import './MobileMenu.css'; // Import the corresponding CSS

const MobileMenu = ({ isOpen, toggleMenu }) => {
  return (
    <div className={`mobile-menu ${isOpen ? 'open' : ''}`}>
      {/* Close Icon */}
      <div className="close-icon" onClick={toggleMenu}>
        <FaTimes />
      </div>
      
      {/* Navigation Links */}
      <div className="mobile-menu-links">
        {/* <a href="#home" onClick={toggleMenu}>Home</a> */}
        <a href="#home" onClick={toggleMenu}>Product</a>
        <a href="#testimonials" onClick={toggleMenu}>Testimonials</a>
        <a href="#team" onClick={toggleMenu}>Team</a>
      </div>
    </div>
  );
};

export default MobileMenu;
