import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './UpgradePopup.css';

const UpgradePopup = ({ isOpen, handleClose }) => {
  const navigate = useNavigate();
  const [isWeekly, setIsWeekly] = useState(false); // State for billing cycle toggle
  const [selectedPlan, setSelectedPlan] = useState(1); // State for selected plan

  if (!isOpen) {
    return null; // Do not render if the popup is closed
  }

  // Toggle between monthly and yearly pricing
  const handleBillingToggle = () => {
    setIsWeekly((prev) => !prev);
  };

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  // Handle confirmation
  const handleConfirm = () => {
    let pid;
    if (selectedPlan === 0) {
      // process.env.REACT_APP_ENVIRONMENT_TYPE === 'DEV' ? navigate(`/payment/?pid=RAZB9vQMpvfQSd`) : ;
      // navigate(`/payment/?pid=RAZMTyeZ0I3liW`);
      isWeekly ? pid = 'R57yhIPFC7GbGB' : pid = 'RAZMTyeZ0I3liW';
      // isWeekly ? pid = 'R58LQs8yGikFvT' : pid = 'RAZB9vQMpvfQSd'; // This is for testing
    }
    else if (selectedPlan === 1) {
      // navigate(`/payment/?pid=RAZQ0dgxsb5ka0`);
      isWeekly ? pid = 'RIXHjuTgN2gSOD' : pid = 'RAZQ0dgxsb5ka0';
    }
    else if (selectedPlan === 2) {
      // navigate(`/payment/?pid=RAZRg4rI31zjTd`);
      isWeekly ? pid = 'RIXImK7AIvEb2i' : pid = 'RAZRg4rI31zjTd';
    }
    navigate(`/payment?pid=${pid}&isRecurring=${isWeekly}`)
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-header">
          <h2>Get Model Credits</h2>
          <button className="close-button" onClick={handleClose}>✕</button>
        </div>
        <p>
        In line with our mission, we offer free credits/week. However, running advanced AI-models is expensive. So, we also offer paid options. <br/><br/>Paid model credits do not expire.<br/>
        </p>

        <div className="billing-toggle">
          <span>One-time</span>
          <label className="switch">
            <input type="checkbox" checked={isWeekly} onChange={handleBillingToggle} />
            <span className="slider round"></span>
          </label>
          <span>Subscription</span>
        </div>

        <div className="plan-options">
          <button
            className={`plan basic ${selectedPlan === 0 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(0)}
          >
            <h3>{isWeekly ? '$3.99' : '$4.99'}</h3>
            <p>{isWeekly ? 'Billed weekly' : 'One Time'}</p>
            {/* <h3>$4.99</h3> */}
            <ul>
              <li>{isWeekly ? '✔ 20% off w/ subscription' : '✔ Easiest to Start'}</li>
              <li>✔ $1.66 per Model Credit</li>
              <li>✔ If you trade 1x/week</li>
            </ul>
          </button>
          <button
            className={`plan investor ${selectedPlan === 1 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(1)}
          >
            <h3>{isWeekly ? <>$8.99</> : <>$11.99</>}</h3>
            <p>{isWeekly ? 'Billed weekly' : 'One-time'}</p>
            {/* <h3>$11.99</h3> */}
            <ul>
              <li>{isWeekly ? '✔ 25% off w/ subscription' : '✔ Save 28% (or $4.5)'}</li>
              <li>✔ $1.20 per Model Credit</li>
              <li>✔ If you trade 3-5x/week</li>
            </ul>
          </button>
          <button
            className={`plan investor ${selectedPlan === 2 ? 'active' : ''}`}
            onClick={() => handlePlanSelect(2)}
          >
            <h3>{isWeekly ? <>$12.99</> : <>$18.99</>}</h3>
            <p>{isWeekly ? 'Billed weekly' : 'One-time'}</p>
            {/* <h3>$18.99</h3> */}
            <ul>
              <li>{isWeekly ? '✔ 32% off w/ subscription' : '✔ Save 29% (or $7.5)'}</li>
              <li>✔ $0.90 per Model Credit</li>
              <li>✔ Best for Daily Traders</li>
            </ul>
          </button>
        </div>
        <div className="popup-footer">
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button className="confirm-button" onClick={() => handleConfirm()}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default UpgradePopup;
