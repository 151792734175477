import React from 'react';
import './TradingSummarySection.css';
import tradingImg1 from '../assets/images/trading-img1.png'; // Adjust the path as needed
import tradingImg2 from '../assets/images/trading-img2.png'; // Adjust the path as needed

const TradingSummarySection = () => {
  return (
    <div id="home" className="trading-summary-section">
      <h3>Focus on signals that matter</h3>
      <div className="trading-search-row">
        {/* Left Column */}
        <div className="trading-left-column">
          <img src={tradingImg1} alt="Trading Image 1" className="trading-img" />
        </div>
        {/* Right Column */}
        <div className="trading-right-column">
          <img src={tradingImg2} alt="Trading Image 2" className="trading-img" />
        </div>
      </div>
    </div>
  );
};

export default TradingSummarySection;
